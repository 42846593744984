$ghost-color: #fff;

.ws-content-cta {
	.cta {
		overflow: hidden;
	}

	.cta-background {
		left: 0;
		top: 0;
		backface-visibility: hidden; // added to fix webkit bug jitter
	}

	.cta-content {
		z-index: 1;

		> * {
			// Required setting for IE11
			// Fixes overflow of content
			max-width: 100%;
		}

		a {
			&:hover,
			&:focus {
				outline: none;
			}
		}
	}

	// btn-ghost-white is not a globally-available button style.
	// Initially intended use is within video-hero sections as part of NADA 2025 designs.
	.btn-ghost-white {
		color: $ghost-color;
		border: 1px solid $ghost-color;
		background: transparent;

		&:hover,
		&.hover,
		&:focus,
		&.focus {
			color: $ghost-color;
		}
	}

	&.interactive-border {
		.btn-ghost-white {
			border-color: transparent;

			// Enables border accessibility when tabbing.
			// Also enables border for the button when the entire widget is not clickable ("enableClickable = false").
			&:hover,
			&.hover,
			&:focus,
			&.focus {
				border-color: $ghost-color;
			}
		}

		// For when the entire widget is clickable ("enableClickable = true")
		.hover .btn-ghost-white {
			border-color: $ghost-color;
		}
	}
}
